import * as React from "react";
import { useStyletron } from "baseui";
import { LabelLarge } from "baseui/typography";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CancelAndSaveButtons } from "../../../cancelSaveButtons";
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList";
import { ZenInput, ZenTextArea } from "../../../zenComponents/zenInput";
import { IntakeScrollDiv } from "../intakeComponent";
import { IntakeAdultProps, intakeURLParser } from "./intakeAdultBaseForm";
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker";
import { ZenButton } from "../../../zenComponents/zenButtons";

export const IntakeAdultCareContext = (props: IntakeAdultProps) => {
  const { clientID, formID, isReadOnly, validationTriggered } = props;
  const [css] = useStyletron();
  const history = useHistory();

  const { watch, trigger, control, errors, getValues, setValue } = useFormContext();
  const receivingFormalSupports = watch("receivingFormalSupports");

  // Initialize careRecipients with default state
  const [careRecipients, setCareRecipients] = React.useState([
    { id: 1, name: "", relationship: "", mentalHealthDiagnosisConcern: "" },
  ]);

  const validation = React.useCallback(async () => {
    const currentState = getValues();

    const checkList: string[] = [];
    if (currentState.receivingFormalSupports) checkList.push("formalSupportDetail");

    const isValid = await trigger(checkList);
    return isValid;
  }, [trigger, getValues]);

  React.useEffect(() => {
    if (validationTriggered) validation();
  }, [validation, validationTriggered]);

  React.useEffect(() => {
    const currentCareRecipients = getValues("careRecipients") || [];
    console.log("%c 🇰🇾: IntakeAdultCareContext -> currentCareRecipients ", "font-size:16px;background-color:#540c0c;color:white;", currentCareRecipients)
    if (currentCareRecipients.length === 0) {
      setCareRecipients([{ id: 1, name: "", relationship: "", mentalHealthDiagnosisConcern: "" }]);
      setValue("careRecipients", [{ id: 1, name: "", relationship: "", mentalHealthDiagnosisConcern: "" }]);
    } else {
      setCareRecipients(currentCareRecipients);
    }
  }, [getValues, setValue]);




  const onSubmit = async () => {
    const isValid = await validation();
    if (!isValid) return;

    // Route to next page
    history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/carerStar`, formID));
  };



  const container = css({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    flexDirection: "row",
  });

  const formStyle = css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "25px",
    paddingTop: "25px",
  });

  const body = css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
  });

  const subForm = css({
    padding: "20px",
  });

  const addCareRecipient = () => {
    const updatedCareRecipients = [
      ...careRecipients,
      { id: careRecipients.length + 1, name: "", relationship: "", mentalHealthDiagnosisConcern: "" },
    ];
    setCareRecipients(updatedCareRecipients);
    setValue("careRecipients", updatedCareRecipients); // Sync with React Hook Form
  };
  

  const deleteCareRecipient = (id: number) => {
    if (id === 1) return; // Prevent deletion if it's the first care recipient
    const updatedCareRecipients = careRecipients.filter((recipient) => recipient.id !== id);
    setCareRecipients(updatedCareRecipients);
    setValue("careRecipients", updatedCareRecipients); // Update RHF form state
  };

  return (
    <div className={container}>
      <form autoComplete="off" className={formStyle}>
        <div className={body}>
          <LabelLarge>Client Context</LabelLarge>
          <IntakeScrollDiv>
            <div className={subForm}>
              {careRecipients.map((recipient, index) => (
                <div key={recipient.id} className={css({ marginBottom: "20px" })}>
                  <ZenInput
                    disabled={isReadOnly}
                    label={`Care Recipient ${index + 1} Name`}
                    nameRef={`careRecipients[${index}].name`}
                    formRef={control}
                  />
                  <ZenInput
                    disabled={isReadOnly}
                    label="Relationship"
                    nameRef={`careRecipients[${index}].relationship`}
                    formRef={control}
                    placeholder="Enter relationship"
                  />
                  <ZenTextArea
                    disabled={isReadOnly}
                    label="Mental Health Diagnosis/Concern"
                    nameRef={`careRecipients[${index}].mentalHealthDiagnosisConcern`}
                    formRef={control}
                    placeholder="Enter details"
                    initialHeight={180}
                  />


                  {index > 0 && (
                    <ZenButton type="button" onClick={() => deleteCareRecipient(recipient.id)}>
                      Delete Care Recipient
                    </ZenButton>
                  )}
                </div>
              ))}

              <ZenButton type="button" onClick={addCareRecipient}>
                Add another Care Recipient
              </ZenButton>
            </div>

            {/* New Section: Home and Family */}
            <LabelLarge marginTop="10px">Home and Family</LabelLarge>
            <div className={subForm}>
              <ZenTextArea
                disabled={isReadOnly}
                label="Who is living in the home"
                nameRef="homeFamilyDetails"
                formRef={control}
                placeholder="Enter details of people living in the home"
                initialHeight={180}
                getValues={getValues}
              />
            </div>

            <LabelLarge marginTop="10px">Current Carer Concerns</LabelLarge>
            <div className={subForm}>
              <ZenTextArea
                disabled={isReadOnly}
                label="Reasons for referral, impact of the issues, duration of issues etc."
                nameRef="currentCarerConcerns"
                formRef={control}
                placeholder="Enter details"
                initialHeight={180}
                getValues={getValues}
              />
            </div>
          </IntakeScrollDiv>
        </div>
        <ErrorFieldTracker errorIDs={Object.keys(errors)} />
        {!isReadOnly && (
          <CancelAndSaveButtons
            cancelLabel="Back"
            cancelFn={() => history.goBack()}
            saveLabel="Next"
            saveFn={onSubmit}
          />
        )}
      </form>
    </div>
  );
};
